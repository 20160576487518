import React, { Fragment, useEffect, useState } from "react";  
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";  
import Sidebar from "../../components/Sidebar";
import SidebarItem from "../../components/Sidebar/SidebarItem";
import {Link} from "react-router-dom"; 
import axios from 'axios';
import ServicesData from '../../data/Services/EnvironmentalServices.json' 


const ServiceContentWrap = ({service, serviceList}) => {
    // servicelist was perviously was from the parent service
    // as per requirement it is now the service list accordingly
    
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
            const response = await axios.get('https://portal.ecotecglobal.net/api/services');
                setData(response.data.data);
                console.log('akash', response.data.data[1].service_category[0].services[0])
            } catch (err) {
                console.log(err);
            } finally {
                // nothing
            }
        };
        
        fetchData();
    }, []);

    return (
        <Fragment>

            <div className="col-md-9 col-sm-12 single_service_page_content"> 
            <img src={('https://portal.ecotecglobal.net/public/' + service.singleThumb)} alt="service" />
                <div className="single_service_title">
                    <h3>{service.title}</h3> 
                    {<div dangerouslySetInnerHTML={{__html: service.description}}/>}
                </div>
            </div>

            <Sidebar classes={'col-md-3 col-sm-12 single_service_side_bar'}>
                <SidebarItem classes="single_service_category">
                    <List>
                        {
                            (data != null) ? data.map((serviceItem,id)=>(
                                <LI key={id}><i className="fa fa-angle-right"></i>
                                    <Link to={`${process.env.PUBLIC_URL +`service/details?id=${serviceItem.service_category[0].services[0].id}`}`}>
                                        {serviceItem.title}
                                    </Link>
                                </LI>
                            )) : null
                        }
                    </List> 
                </SidebarItem>
            </Sidebar>

        </Fragment>
    );
};

export default ServiceContentWrap;