import React from 'react';
import AboutList from "./AboutList";
import SectionTitle from "../../SectionTitles/SectionTitle";
import AboutData from "../../../data/About/about-one";


const PrivacyPolicy = () => {

    return (

        <section className="about-section" id="about">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-12 about_bottom_warp">
                        <div className="about_list">
                            {/* Start: Heading */}
                            <SectionTitle
                                title=" Welcome to ECOTEC "
                            />



                            <p style={{textAlign: "justify"}}></p>

                            <b>PRIVACY AND DATA PROTECTION POLICY</b>

                            <p style={{textAlign: "justify"}}> <b>ECOTEC GLOBAL</b> (“ECOTEC”) is committed to comply with the legislation requirements as stipulated in The Data Protection Act 2023. The Data Protection Act 2023 is the Bangladesh’s implementation of the General Data Protection Regulation (GDPR).</p>
                            <p style={{textAlign: "justify"}}> ECOTEC is responsible for using your personal data that following data protection principles.</p>
                            <p style={{textAlign: "justify"}}> Information received by the Company will not be used for any personal gain, nor will it be used for any purpose beyond that for which it was given. The Company will at all times ensure that it complies with all applicable requirements of the Data Protection Legislation.</p>
                            <p style={{textAlign: "justify"}}> We collect information about your organisation for the purpose of audits, inspection and verification and certification. These information including name of personnel, contact details, nature of business, type of products or services, processes and operational activities, objective evidence including photographs of compliance to requirements of standards, worker personal details during individual or group interview and all other relevant information deem required during audits.</p>
                            <p style={{textAlign: "justify"}}> The information you provide us is used for purpose of certification only where this information is available to the Accreditation Bodies and Scheme Owners. The Accreditation Bodies and Scheme Owners ensure confidentiality through Non-Disclosure Agreements.</p>
                            <p style={{textAlign: "justify"}}> We always make sure that your personal information is accurate and up to date. You may ask us to update your information and ask us to correct or remove information you think is inaccurate.  </p>
                            <p style={{textAlign: "justify"}}> You have the right to request a copy of the information that we hold about you. If you would like a copy of some or all your personal information, please email to us at <b><a target='blank' href="https://mail.google.com/mail/?view=cm&fs=1&to=info@ecotecglobal.net">info@ecotecglobal.net</a></b> </p>
                            <p style={{textAlign: "justify"}}> We keep our Privacy and Data Protection Policy under regular review, and we will place any updates on our website.</p>
                            <p style={{textAlign: "justify"}}> Please contact us if you have any questions about our Privacy Policy, or information we hold about you. </p>
                            <p style={{textAlign: "justify"}}> Email: <b><a target='blank' href="https://mail.google.com/mail/?view=cm&fs=1&to=info@ecotecglobal.net">info@ecotecglobal.net</a></b></p>
                        </div>
                        {/* `https://mail.google.com/mail/?view=cm&fs=1&to=info@ecotecglobal.net` */}
                    </div>

                    {  /*Start: About Image  */}
                    <div className="col-md-6 col-sm-12">

                        { /*  About Right Image  */}
                        <img src={process.env.PUBLIC_URL + '/assets/images/about.png'} alt="" />

                    </div>
                    {/*End: About Image  */}


                </div>
                {/* row */}
            </div>
            {/* container */}
        </section>


    );
};

export default PrivacyPolicy;
