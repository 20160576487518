import React from 'react';

function UseFullLinkArea() {
    return (
        <div className="download" style={{marginLeft: '-20px'}}>
            <marquee direction="up" truespeed="" scrolldelay="65" scrollamount="1"
                     behavior="scroll" height="149px" align="middle">
                <ul>
                    <li><a className="footer-download-link-bdcolor" href="https://mole.gov.bd" target="_blank"> Labor Ministry</a></li>
                    <li><a className="footer-download-link-bdcolor" href="http://www.dife.gov.bd" target="_blank"> Factory Inspector</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://www.bgmea.com.bd" target="_blank"> BGMEA</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://bkmea.com" target="_blank"> BKMEA</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://www.bepza.gov.bd" target="_blank"> BEPZA</a></li>
                    <li><a className="footer-download-link-bdcolor" href="http://www.doe.gov.bd" target="_blank"> Department of Environment (DoE)</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://moef.gov.bd/index.php" target="_blank"> Ministry of Environment, Forest and Climate Change</a></li>
                    <li><a className="footer-download-link-bdcolor" href="http://www.bab.org.bd" target="_blank"> Bangladesh Accreditation Board (BAB)</a></li>
                    <li><a className="footer-download-link-bdcolor" href="http://www.bsti.gov.bd" target="_blank"> BSTI</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://www.who.int/en" target="_blank"> World Health Organization (WHO)</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://www.eea.europa.eu" target="_blank"> European Environmental Agency</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://www.epa.gov" target="_blank"> EPA</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://www.ilo.org/global/lang--en/index.htm" target="_blank"> ILO</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://www.usgbc.org/leed" target="_blank"> LEED</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://apparelcoalition.org" target="_blank"> SAC</a></li>
                    <li><a className="footer-download-link-bdcolor" href="http://nbr.gov.bd" target="_blank"> NATIONAL BOARD OF REVENUE (NBR)</a></li>
                    <li><a className="footer-download-link-bdcolor" href="https://www.laborlawpartner.com/download.php" target="_blank"> Local Laws</a></li>
                     


                </ul>
            </marquee>
        </div>
    );
}

export default UseFullLinkArea;