import React from 'react';
import AboutList from "./AboutList";
import SectionTitle from "../../SectionTitles/SectionTitle";
import AboutData from "../../../data/About/about-one";


const AboutEcotec = () => {

    return (

        <section className="about-section" id="about">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-12 about_bottom_warp">
                        <div className="about_list" style={{textAlign: 'justify'}}>
                            {/* Start: Heading */}
                            <SectionTitle
                                title=" Welcome to ECOTEC "
                            />


                            <p>ECOTEC Global (ECOTEC) is a global Assessment, Advisory, Sustainability and Training firm in ESG Domain (Environment, Social and Governance). We deliver one stop services that consists of Assessment, Training & Capacity Building, Advisory, ESG Reporting and Corporate Services that drive positive impact.</p>
                            <p>Founded in 2016, the global assurance provider – ECOTEC Global has nine years of experience. We are headquartered in Dhaka, Bangladesh and covering South & Southeast Asia. Our team of 30-plus industry experts support clients in more than 7 countries, which includes about 11 corporate clients that work with ECOTEC Global as a trusted due diligence partner.</p>
                            <p>It is increasingly clear that companies will have to transform their programs to meet a new higher standard of due diligence. This requires a commitment to transparency, visibility and thoughtful management of risk and performance. It requires a commitment to business-driven sustainability.</p>
                            <p>We are committed to enhancing the ESG (Environmental, Social and Governance) competitiveness for brands and retailers supply chains, as well as to creating a transparent and reliable supply chain for our clients. </p>
                            <br />
                            <strong class='redText'>Our Services</strong>
                            <p>We offer a comprehensive range of ESG consulting services designed to meet the specific needs of our clients:</p>
                            <ul>
                                <div class='listDiv'>
                                    <div class='leftList'> 
                                        <li><strong>Risk Management and Mitigation: </strong></li>
                                        <li><strong>Audit & Assessment</strong></li>
                                        <li><strong>Advisory </strong></li>
                                        <li><strong>Training & Development</strong></li>
                                        <li><strong>Project Management</strong></li>
                                    </div>
                                    <div class='rightList'>
                                        <li><strong>Environmental Sustainability</strong></li>
                                        <li><strong>RSC & NIRAPON</strong></li>
                                        <li><strong>Digital Solutions</strong></li>
                                        <li><strong>Accounting, Tax and Corporate Services</strong></li>
                                        <li><strong>Investment Consulting</strong></li>
                                    </div>
                                </div>
                            </ul>
                            <p>“Be Responsible, Be Sustainable!”</p>                            
                            <strong class='redText'>Our Vision:</strong>
                            <ul>
                                <li>To set up incentive driven mechanism for integrity compliance</li>
                                <li>To be an outstanding, reliable & excellent training and development firm</li>
                                <li>To be an outstanding, reliable & excellent training and development firm</li>
                                
                            </ul>
                            <br />
                            <strong class='redText'>Our Mission:</strong>
                            <p>TO FACILITATE TRUST & VALUE IN SUPPLY CHAIN RELATIONSHIPS</p>
                        </div>

                    </div>

                    {  /*Start: About Image  */}
                    <div className="col-md-6 col-sm-12">

                        { /*  About Right Image  */}
                        <img src={process.env.PUBLIC_URL + '/assets/images/about.png'} alt="" />

                    </div>
                    {/*End: About Image  */}


                </div>
                {/* row */}
            </div>
            {/* container */}
        </section>


    );
};

export default AboutEcotec;
