import React from 'react';   
import AboutList from "./AboutList"; 
import SectionTitle from "../../SectionTitles/SectionTitle";
import AboutData from "../../../data/About/about-one";
import { Link } from 'react-router-dom';
 

const AboutShort = () => {
    const serviceURL = `/about-ecotec`
  return (
 
	<section className="about-section" id="about">
        <div className="container"> 
            <div className="row">
                <div className="col-md-6 col-sm-12 about_bottom_warp">
                    <div className="about_list" style={{textAlign: 'justify'}}>
                        {/* Start: Heading */}
                        <SectionTitle 
                        title=" Welcome to ECOTEC "  
                        />
                     
                
                        <p>ECOTEC GLOBAL (ECOTEC) is a leading Consulting Firm covering Sustainability Reporting, Training and Development, ESG & Due Diligence, Safety & Risk, Corporate Services and Environmental Solutions.</p>
                        <p>Founded in 2016, it has become a trusted leader in providing comprehensive ESG (Environmental, Social, and Governance) consulting services. Our firm is dedicated to helping businesses integrate sustainable practices, enhance social responsibility, and uphold strong governance standards. With a commitment to sustainability and ethical business conduct, we aim to create lasting positive impacts for our clients and the communities they serve.</p>                     
                        <Link to={`${process.env.PUBLIC_URL + serviceURL}`} className="serv_read">Read More <span className="fa fa-angle-double-right"></span></Link>
                     </div>

                    <div className="row about_list_warp"> 

                       
                        
                    </div>
                </div>

                {  /*Start: About Image  */} 
                <div className="col-md-6 col-sm-12"> 

                    { /*  About Right Image  */}
                    <img src={process.env.PUBLIC_URL + '/assets/images/about.png'} alt="" />

                </div> 
                {/*End: About Image  */}
            

            </div>
            {/* row */}
        </div>
        {/* container */}
    </section>


  );
};

export default AboutShort;
